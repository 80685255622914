import "./App.css";
import { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, getTranscript, getInteractionFeedback } from "./firebase";
import { v4 } from "uuid";
import Spinner from "reactjs-simple-spinner";

function App() {
  const [audioUpload, setaudioUpload] = useState(null);
  // const [email, setEmail] = useState(null);
  const [interactionTranscript, setInteractionTranscript] = useState(null);
  const [interactionFeedback, setInteractionFeedback] = useState(null);

  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  useEffect(() => {
    if (interactionTranscript) {
      setFeedbackLoading(true);
      getInteractionFeedback({
        interactionTranscript:
          // "speaker 1: woow, i really like your hair, speaker 2: well its not real, speaker 1: oh sorry I didnt mean to upset you speaker 2: no its ok! speaker 1: well its a wonderful day, lets go for a coffee speaker 2: ehm no thanks",
          interactionTranscript,
      }).then((res) => {
        setFeedbackLoading(false);
        setInteractionFeedback(res.data);
      });
    }
  }, [interactionTranscript]);

  const uploadFile = () => {
    if (audioUpload == null) return;
    setTranscriptLoading(true);
    const audioRef = ref(storage, `audios/${audioUpload.name + v4()}`);
    uploadBytes(audioRef, audioUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then(async (url) => {
        const response = await getTranscript({
          audioUrl: url,
        });
        setTranscriptLoading(false);
        setInteractionTranscript(response.data);
      });
    });
  };

  return (
    <div className="App">
      <input
        type="file"
        accept="audio/mp3, audio/x-m4a, audio-mpeg"
        onChange={(event) => {
          setaudioUpload(event.target.files[0]);
        }}
      />
      {/* <label>
        Email:
        <input
          type="email"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
      </label> */}
      <button onClick={uploadFile} disabled={!audioUpload}>
        Upload Interaction Audio
      </button>
      <div>
        <h2>Transcript</h2>

        {transcriptLoading ? (
          <Spinner message="Loading..." />
        ) : (
          interactionTranscript
        )}
      </div>
      <div>
        <h2>AI Coach Feedback</h2>
        {feedbackLoading ? (
          <Spinner message="Loading..." />
        ) : (
          interactionFeedback
        )}
      </div>
    </div>
  );
}

export default App;
