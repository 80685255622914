import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD555dqqwd-pwIiGxuWwigUvsv27wXK7ck",
  authDomain: "confidai.firebaseapp.com",
  projectId: "confidai",
  storageBucket: "confidai.appspot.com",
  messagingSenderId: "926709498668",
  appId: "1:926709498668:web:04d463082eca8f11e9a289",
  measurementId: "G-W9GEX4QC1R",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const getTranscript = httpsCallable(functions, "getTranscript");
export const getInteractionFeedback = httpsCallable(
  functions,
  "getInteractionFeedback"
);
